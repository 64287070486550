import { useEffect, useState } from "react";
import { ImageSwapper } from '../ImageSwap';
import { lazy, Suspense } from "react";

const logo = require('../assets/img/logo-invertiert.webp');
const bg1 = require('../assets/img/background.webp');
const bg2 = require('../assets/img/background2.webp');
const bg3 = require('../assets/img/background3.webp');
const images = [bg1, bg2, bg3];
setTimeout(()=>{    
    images.forEach(image => {
    const imageElement = new Image();
    imageElement.src = image;
});}, 3000)
export const Home = () => {
    const [count, setCount] = useState(0);
    const startDate = new Date("2025-03-15");
    const endDate = new Date("2025-03-17"); 
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const shouldShowMessage = today >= startDate && today <= endDate;
   
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCount((count) => (count + 1) % images.length);
        }, 5000);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <>
            <section id="home">
                <div className={'bg img' + count}></div>
                <div className="home-container">
                    <div className='container'>
                        <div className="home-content">
                            <div className="logo">
                                <img src={logo} alt="logo" className="img-fluid" />
                            </div>
                            {shouldShowMessage && (
                                <div className="cafe-info my-5">
                                    Am {startDate.toLocaleDateString()} und {endDate.toLocaleDateString()} geschlossen
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};